









































import Vue from "vue";
import { mapActions } from "vuex";

import { delay } from "@/utils";
import { Profile } from "@/types/Member";
import { SubscriptionTopic } from "@/types/Subscription";
import {
  getFullProfile,
  updateFullProfile,
  listSubscriptionOptions,
  updateSubscriptionOption,
} from "@/utils/backend";
import { displayError } from "@/utils/message";

import MemberApplicationForm from "@/components/member/ApplicationForm.vue";
import MemberApplicationFormRegion from "@/components/member/ApplicationFormRegion.vue";

export default Vue.extend({
  components: {
    MemberApplicationForm,
    MemberApplicationFormRegion,
  },
  name: "Profile",
  data() {
    return {
      processing: false,
      profile: {
        class_year_searchable: true,
        profession_searchable: true,
      },
      subscriptionOptions: [] as Array<SubscriptionTopic>,
    };
  },
  methods: {
    ...mapActions(["showLoading", "hideLoading", "displayMessage"]),
    async save() {
      if (this.processing) return;
      this.processing = true;
      try {
        this.showLoading();

        const chineseNameParams = await (
          this.$refs.form as any
        ).getChineseName();

        const addressParams = await (
          this.$refs.form as any
        ).getResidentialAddress();

        const educationParams = await (
          this.$refs.form as any
        ).getEducationAndQualifications();

        const contactParams = await (this.$refs.form as any).getContactInfo();

        const result = await updateFullProfile({
          ...chineseNameParams,
          ...addressParams,
          ...contactParams,
          ...educationParams,
          ...this.profile,
        });
        (this.$refs.form as any).setItem(result);

        for (const option of this.subscriptionOptions) {
          await updateSubscriptionOption(option.id, option);
        }

        await delay(1000);
        this.hideLoading();
        this.processing = false;

        this.displayMessage("Your Profile was updated.");
      } catch (err) {
        this.processing = false;
        this.hideLoading();

        if (err === "FORM_INVALID") {
          // this.displayMessage(displayError(err));
          // Form contains invalid fields but were not editable for the user. Therefore need to notify user to contact admin to modify.
          this.displayMessage(
            "Please contact the administrator to update your profile. Sorry for any inconvenience."
          );
        } else {
          this.displayMessage(displayError(err?.error.code));
        }

        console.error(err);
      }
    },
    async fetch() {
      const profile: Profile = await getFullProfile();
      (this.$refs.form as any).setItem(profile);

      this.profile.class_year_searchable = profile.class_year_searchable;
      this.profile.profession_searchable = profile.profession_searchable;
    },
    async fetchSubscriptionOptions() {
      const results = await listSubscriptionOptions();
      this.subscriptionOptions = results;
    },
  },
  mounted() {
    this.fetch();
    this.fetchSubscriptionOptions();
  },
});
